<template>
  <ul class="menu-list">
    <li
      class="ml-item"
      :class="{active: mapCurrent == item.id}"
      v-for="(item,index) in menuList" :key="index"
    >
      <!-- <router-link class="ml-link" :to="item.path">{{item.name}}</router-link> -->
      <div class="ml-link" @click="menuChangeFun($event,item)">{{item.name}}</div>
    </li>
  </ul>
</template>

<script>
export default {
  // 允许组件模板递归地调用自身
  name: 'myMenu',
  // 声明一组可用于组件实例中的组件
  components: {

  },
  // 一个用于从父组件接收数据的数组或对象
  props: {
    menuList : {
      type: Array,
      default: [],
    },
    current: {
      default: 1,
    },
  },
  // 该函数返回组件实例的 data 对象
  data () {
    return {
      mapCurrent: this.current
    }
  },
  // 计算属性：
  computed: {

  },
  // 钩子函数--侦听data变动：
  watch: {
    current: function(e) {
      console.log('current e == ',e)
      this.mapCurrent = e;
    }
    // 监听路由变化，并同步到导航组件
    // $route(to,from){
    //   console.log('监听路由变化 菜单 to == ',to);
    //   console.log('监听路由变化 菜单 from == ',from);
    //   let query = to.query;
    //   if(query) {
    //     let id = query.id;
    //     console.log('监听路由变化 菜单 id == ',id);
    //     if(id) {
    //       this.current = id;
    //     }
    //   }
    // }
  },
  // 在实例创建完成后被立即同步调用
  methods: {
    /**
     * 菜单变换
     */
    menuChangeFun(e,item) {
      let { id, status = 1, status_info = {}, is_callback } = item;
      console.log('菜单变换 e == ',e)
      console.log('菜单变换 id == ',id)
      if(status == 1) { // 正常
        this.mapCurrent = id;
        this.$emit('menu-change',{index:id})
      } else if( status == 2) { // 不允许跳转并且提示
        if(is_callback) {
          this.informChangeFun({
            visible: false,
            status: 1,  // 1/成功 2/失败 3/取消
            message: '取消',
            data: '',
          });
        }
        let { message } = status_info;
        this.myMessage({
          message: message,
        })

      }
    },
    /**
     * 告知弹窗变化
     */
    informChangeFun(opt) {
      let default_data = {
        visible: false,
        status: 3, // 1/成功 2/失败 3/取消
        message: '取消',
        data: '',
        event: 'on-callback',
      };
      let result = Object.assign(default_data,opt);
      this.$emit(default_data.event,result)
      // 默认变化通知
      this.informDefaultchangeFun(default_data);
    },
  },
  // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
  created() {

  },
  // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
  mounted() {

  },
  beforeCreate() { // 创建前

  },
  beforeMount() { // 挂载前

  },
  beforeUpdate() { // 更新前

  },
  updated() { // 更新后

  },
  beforeDestroy() { // 销毁前

  },
  destroyed() { // 销毁后

  },
  activated() { // 缓存保持，需要由 keep-alive 触发

  },
}
</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
.menu-list {
  .ml-item {
    box-sizing: border-box;
    width: 100%;
    // height: 46px;
    // line-height: 46px;
    margin-top: 20px;
    text-align: left;
    &:first-of-type {
      margin-top: 0;
    }
    &.active {
      // background: #f4f4f4;
      .ml-link {
        color: #E62129;
      }
    }
    .ml-link {
      cursor: pointer;
      display: inline-block;
      // width: 100%;
      // height: 100%;
      font-size: 16px;
      color: #787878;
    }
  }
}
</style>
