<template>
  <div class="container">
    <div class="status" v-if="is_pass ? !is_pass : userInfo.store_status > 0">
      <div class="status-main">
        <div class="cover">
          <el-image
            class="fake-img"
            :src="ossUrl + '/images/identification-img/status-icon (' + userInfo.store_status + ').png'"
            fit="scale-down"
            lazy
          ></el-image>
        </div>
        <p class="title">
          <span v-if="userInfo.store_status == 1">
            <span v-if="userInfo.identity == 1">个人</span>
            <span v-if="userInfo.identity == 2">企业</span>
            <span>认证成功</span>
          </span>
          <span v-if="userInfo.store_status == 2">审核中请耐心等待</span>
          <span v-if="userInfo.store_status == 3">认证失败</span>
        </p>
        <div class="control" v-if="userInfo.store_status == 1 || userInfo.store_status == 3">
          <el-button
            class="fake-btn"
            v-if="userInfo.store_status == 1"
            @click="skipToPageFun(userInfo.auth_identity)"
          >
            <span>查看/修改</span>
          </el-button>
          <el-button
            class="fake-btn"
            v-if="userInfo.store_status == 3"
            @click="skipToPageFun(userInfo.auth_identity)"
          >
            <span>重新认证</span>
          </el-button>
        </div>
      </div>
    </div>
    <div class="mian" v-else>
      <div class="menu-box">
        <ul class="mb-list">
          <li class="mb-item">
            <!-- <div class="mb-title">
              <h3 class="title-text">需求发布</h3>
            </div> -->
            <div class="mb-container">
              <my-menu :menu-list="menu_list" :current="current" @menu-change="onMenuChangeFun" />
            </div>
          </li>
        </ul>
      </div>
      <div class="show-body">
        <keep-alive>
          <component :is="page_temp" :options="formData" :sign="sign"></component>
        </keep-alive>
      </div>
    </div>
  </div>
</template>

<script>
import myMenu from '@/components/my-menu/MyMenu.vue';
import personage from '@/views/identification/components/personage.vue';
import enterprise from '@/views/identification/components/enterprise.vue';
export default {
  // 允许组件模板递归地调用自身
  name: 'Identification',
  // 声明一组可用于组件实例中的组件
  components: {
    myMenu,
    personage,
    enterprise,
  },
  // 一个用于从父组件接收数据的数组或对象
  props: {

  },
  // 该函数返回组件实例的 data 对象
  data () {
    return {
      ossUrl: this.GLOBAL.ossUrl,
      is_pass: false, // 通过
      userInfo: { // 用户账号相关信息
        loading: false,
      },
      formData: {}, // 表单数据
      sign: 1, // 组件标识
      current: 1, // 当前下标
      page_temp: 'personage',
      menu_list: [{
        id: '1',
        name: '个人认证',
        path: '/identification?id=1',
      },{
        id: '2',
        name: '企业认证',
        path: '/identification?id=2',
      }],
    }
  },
  // 计算属性：
  computed: {

  },
  // 钩子函数--侦听data变动：
  watch: {

  },
  // 在实例创建完成后被立即同步调用
  methods: {
    /**
     * 获取页面路由 id
     */
    getPagePathIdFun() {
      return new Promise((resolve,reject) => {
        // 获取当前路由
        this.myGetRouter().then((res) => {
          console.log(res)
          let { current } = res.query;
          resolve(current);
        });
      })
    },
    /**
     * 获取页面数据
     */
    getPageDataFun() {
      // 初始化 tab
      this.initTabFun()
      // 获取账号信息
      this.getUserInfoFun().then(res => {
        // 初始化菜单列表
        this.initMenuListFun(res.identity);
      });
      // 获取商户入驻信息
      this.getMerchantsInInfo()
    },
    /**
     * 初始化菜单列表
     */
    initMenuListFun(type) {
      let menu_list = [{
        id: '1',
        name: '个人认证',
        path: '/identification?id=1',

      },{
        id: '2',
        name: '企业认证',
        path: '/identification?id=2',
      }];

      if(type == 2) {
        menu_list[0].status = 2; // 不允许跳转
        menu_list[0].status_info = {
          message: '入驻成功后个人可更改为企业，企业不能更改为个人'
        };
      }

      this.menu_list = menu_list;
    },
    /**
     * 初始化 tab
     */
    initTabFun() {
      return new Promise(resolve => {
        let id = '1';
        let session = this.myGetSession(this.$options.name);
        console.log('session == ',session);
        if(session) {
          let str_session = String(session);
          id = str_session;
          this.switchTabViewFun(id);
        } else {
          // 获取页面路由 id
          this.getPagePathIdFun().then(res => {
            let reuslt = res || id;
            // 切换视图面板
            this.switchTabViewFun(reuslt);
            // 设置当前页面缓存
            this.setAtPageSessionFun(reuslt);
          })
          // // 设置当前页面缓存
          // this.setAtPageSessionFun(id);
        }
        // // 切换视图面板
        // this.switchTabViewFun(id);
      })
    },
    /**
     * 设置当前页面缓存
     */
    setAtPageSessionFun(val){
      this.mySetSession(this.$options.name,val)
    },
    /**
     * 移除当前页面缓存
     */
    removeAtPageSessionFun(){
      this.myRemoveSession(this.$options.name)
    },
     /**
     * 菜单变换回调
     */
    onMenuChangeFun(e) {
      console.log('菜单变换回调 e ==',e)
      let { index } = e;
      // 切换视图面板
      this.switchTabViewFun(index);
      // 设置当前页面缓存
      this.setAtPageSessionFun(index);
    },
    /**
     * 切换视图面板
     */
    switchTabViewFun(index) {
      index = Number(index) || 1;
      console.log('视图面板 index == ',index)
      this.current = index;
      this.sign = index;
      switch(index) {
        case 1:
            this.page_temp = 'personage';
          break;
        case 2:
            this.page_temp = 'enterprise';
          break;
        default:
            this.page_temp = 'personage';
          break;
      }
    },
    /**
     * 获取账号信息
     */
    getUserInfoFun() {
      return new Promise((resolve) => {
        this.myRequest({
          url: '/api/user/userinfostatus',
        }).then((res) => {
          console.log('获取账号信息 res == ',res)
          let resData = res.data.data;
          this.userInfo = resData;
          if(typeof this.userInfo == 'object') {
            this.userInfo.loading = true;
          }
          resolve(resData);
        })
      })
    },
    /**
     * 获取商户入驻信息
     */
    getMerchantsInInfo() {
      this.myRequest({
        url: '/api/store/storeInfo',
      }).then((res) => {
        console.log('获取商户入驻信息 res == ',res)
        let resData = res.data.data;
        if(resData.is_store_exist) {
          this.formData = resData.info;
        }
      })
    },
    /**
     * 跳转页面函数
     */
    skipToPageFun(type) {
      console.log('跳转页面函数 type == ',type)
      type = type || '1';
      // let url = this.menu_list[0].path;
      // let findItem = this.menu_list.find(item => item.id == type);
      // if(findItem) {
      //   url = findItem.path;
      // }
      this.is_pass = true; // 通过
      // 切换视图面板
      this.switchTabViewFun(String(type));
    }
  },
  // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
  created() {

  },
  // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
  mounted() {

    // 获取页面数据
    this.getPageDataFun();
  },
  beforeCreate() { // 创建前

  },
  beforeMount() { // 挂载前

  },
  beforeUpdate() { // 更新前

  },
  updated() { // 更新后

  },
  beforeDestroy() { // 销毁前
    // 移除当前页面缓存
    this.removeAtPageSessionFun();
  },
  destroyed() { // 销毁后

  },
  activated() { // 缓存保持，需要由 keep-alive 触发

  },
}
</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
.container {
  $row-spacing: 24px;

  .mian {
    box-sizing: border-box;
    flex: 1;
    min-height: 0;
    // background: #f4f4f4;
    display: flex;
  }
  .menu-box {
    box-sizing: border-box;
    width: 170px;
    padding: $row-spacing 0;
    background: #fff;
    border-right: 5px solid #f4f4f4;
    .mb-list {
      .mb-item {
        box-sizing: border-box;
        padding: 0 32px;
        margin-top: 100px;
        &:first-of-type {
          margin-top: 0;
        }
        .mb-title {
          position: relative;
          margin-bottom: 20px;
          &::before {
            content: '';
            display: inline-block;
            width: 3px;
            height: 20px;
            background: #E62129;
            z-index: 1;
            position: absolute;
            top: 6px;
            left: -16px;
          }
          .title-text {
            font-weight: 400;
            font-size: 22px;
            color: #444343;
          }
        }
        .mb-container {}
      }
    }
  }
  .show-body {
    flex: 1;
    min-width: 0;
    // display: flex;
    // justify-content: center;
    // box-sizing: border-box;
    // padding: $row-spacing 0;
    .sb-container {
    }
  }
}



.status {
  margin: 100px auto;
  .status-main {
    .cover {
      margin: 0 auto;
      width: 310px;
      height: 210px;
      .fake-img {
        width: 100%;
        height: 100%;
      }
    }
    .title {
      margin-top: 20px;
      text-align: center;
      font-size: 18px;
      color: #444343;
    }
    .control {
      width: 200px;
      margin: 60px auto 20px;
      .fake-btn {
        width: 100%;
        height: 48px;
        font-size: 18px;
        color: #fff;
        border: 1px solid $common-number1-color;
        background: $common-number1-color;
        outline: none;
        &.el-button:hover,&.el-button:focus,&.el-button:active {
          color: none;
          border-color: none;
          background-color: none;
        }
      }
    }
  }
}

</style>
