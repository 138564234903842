<template>
  <div class="enter-container">
    <div class="form-title-box">
      <form-title title="企业认证" descriptions="Enterprise" text="certification" />
    </div>
    <div class="form-module">
      <div class="fm-itme">
        <div class="fm-key">
          <div class="key-title required-style">所在城市：</div>
        </div>
        <div class="fm-val">
          <div class="field-item">
            <div
              class="select-city"
              :class="{'active-one': formData.province,'active-two': formData.city,'active-three': formData.county}"
            >
              <v-distpicker
                :province="formData.province"
                :city="formData.city"
                hide-area
                :area="formData.county"
                @province="function(e) { formData.province = e.value; formData.city = null; formData.county = null;}"
                @city="function(e) { formData.city = e.value; formData.county = null;}"
                @area="function(e) { formData.county = e.value;}"
                :placeholders="{ province: '省', city: '市', area: '区' }"
              ></v-distpicker>
            </div>
          </div>
        </div>
      </div>
      <div class="fm-itme">
        <div class="fm-key">
          <div class="key-title required-style">法人：</div>
        </div>
        <div class="fm-val">
          <div class="field-item">
            <div class="input-box">
              <el-input v-model="formData.real_name" placeholder="请填写法人姓名" @input="inputChangeFun($event,'real_name')"></el-input>
            </div>
          </div>
        </div>
      </div>
      <div class="fm-itme">
        <div class="fm-key">
          <div class="key-title required-style">联系方式：</div>
        </div>
        <div class="fm-val">
          <div class="field-item">
            <div class="input-box">
              <el-input maxlength="11" v-model="formData.tel" placeholder="请填写您的联系方式" @input="inputChangeFun($event,'tel')"></el-input>
            </div>
          </div>
        </div>
      </div>
      <div class="fm-itme">
        <div class="fm-key">
          <div class="key-title required-style">企业名称：</div>
        </div>
        <div class="fm-val">
          <div class="field-item">
            <div class="input-box">
              <el-input v-model="formData.name" placeholder="请填写您的企业名称" @input="inputChangeFun($event,'name')"></el-input>
            </div>
          </div>
        </div>
      </div>
      <div class="fm-itme">
        <div class="fm-key">
          <div class="key-title required-style">营业执照：</div>
        </div>
        <div class="fm-val">
          <div class="field-item">
            <div class="upload-module">
              <el-upload
                class="upload-main"
                :class="{'hide-upload': formData.prove}"
                ref="upload1"
                :limit="1"
                :name="upload_option.name"
                :data="upload_option.param"
                :auto-upload="false"
                :action="ossBaseUrl"
                :before-upload="handleBeforeUploadFun"
                :on-progress="handleProgressFun"
                :on-success="handleSuccessFun"
                :on-error="handleErrorFun"
                :on-preview="handlePreviewFun"
                :on-remove="function(file, fileList){return handleRemoveFun(file, fileList,'upload1');}"
                :on-change="function(file, fileList){return handleChangeFun(file, fileList,'upload1');}"
                list-type="picture-card"
                :file-list="all_fileList['upload1']"
              >
                <i slot="default" class="el-icon-plus"></i>
                <!-- <div class="default-befoer">
                  <div class="db-icon">
                    <i class="iconfont icon-jiahao"></i>
                  </div>
                </div> -->
              </el-upload>
            </div>
            <!-- <div class="tips-text" slot="tip">只能上传jpg/png文件，且不超过500kb</div> -->
          </div>
        </div>
      </div>
      <div class="fm-rest">
        <div class="fm-confirm">
          <el-button class="fake-btn" @click="clickSubmitBtnFun">
            <span>提交</span>
          </el-button>
        </div>
        <div class="fm-tips">温馨提示：此为个人认证，认证成功后可更改为企业、公司</div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  dataURLToBlob, // 将base64转换为blob
  fileToBase64, // file转base64
} from '@/utils/utils_fun.js';
import formTitle from '@/components/form-title/FormTitle.vue';
import VDistpicker from 'v-distpicker';

export default {
  // 允许组件模板递归地调用自身
  name: 'enterprise',
  // 声明一组可用于组件实例中的组件
  components: {
    formTitle,
    VDistpicker
  },
  // 一个用于从父组件接收数据的数组或对象
  props: {
    options: {
      type: Object,
      default: () => {
        let formData = {
          identity: 2, // 身份  1:个人 2:企业
          province: '', // 所在省
          city: '', // 所在市
          county: '', // 所在区/县
          real_name: '', // 姓名
          tel: '', // 手机
          name: '', // 场地名称/机构名称  身份（2与3）时必填
          address: '', // 场地地址/机构地址/地址
          prove: '', // 营业执照/证书
          lng: '', // 经度
          lat: '', // 纬度
          id_card_front: '', // 身份证含照片的一面（注：当选择身份为个人时为必填参数））
          id_card_back: '', // 身份证带国徽的一面（注：当选择身份为个人时为必填参数）
          soldier_certificate: '', //	否	string	退伍/教练证书
          teachers_certificate: '', // 否	string	培训师资格证书
        }
        return formData;
      },
    },
  },
  // 该函数返回组件实例的 data 对象
  data () {
    return {
      ossBaseUrl: this.GLOBAL.ossBaseUrl,
      ossUrl: this.GLOBAL.ossUrl,
      formData: Object.assign({ //form 表单数据
        identity: 2, // 身份  1:个人 2:企业
        province: '', // 所在省
        city: '', // 所在市
        county: '', // 所在区/县
        real_name: '', // 姓名
        tel: '', // 手机
        name: '', // 场地名称/机构名称  身份（2与3）时必填
        address: '', // 场地地址/机构地址/地址
        prove: '', // 营业执照/证书
        lng: '', // 经度
        lat: '', // 纬度
        id_card_front: '', // 身份证含照片的一面（注：当选择身份为个人时为必填参数））
        id_card_back: '', // 身份证带国徽的一面（注：当选择身份为个人时为必填参数）
        soldier_certificate: '', //	否	string	退伍/教练证书
        teachers_certificate: '', // 否	string	培训师资格证书
      },this.options),
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      // 上传文件配置选项
      upload_option: {
        url: '',
        name: 'file',
        param: {
          key: '',
          policy: '',
          OSSAccessKeyId: '',
          signature: '',
        },
        record_info: {
          name: '',
          url: '',
        }
      },
      all_fileList: { // 文件列表
        'upload1': [],
      },
    }
  },
  // 计算属性：
  computed: {

  },
  // 钩子函数--侦听data变动：
  watch: {
    options: function(e) {
      console.log('options e == ',e)
      this.formData = Object.assign(this.formData,e);
      console.log('options e this.formData == ',this.formData)
      // 初始化文件列表
      this.initFileListFun(e)
    },
  },
  // 在实例创建完成后被立即同步调用
  methods: {
    /**
     * 初始化页面 data 数据
     */
    initPageDataFun() {
      this.formData = { //form 表单数据
        identity: 2, // 身份  1:个人 2:企业
        province: '', // 所在省
        city: '', // 所在市
        county: '', // 所在区/县
        real_name: '', // 姓名
        tel: '', // 手机
        name: '', // 场地名称/机构名称  身份（2与3）时必填
        address: '', // 场地地址/机构地址/地址
        prove: '', // 营业执照/证书
        lng: '', // 经度
        lat: '', // 纬度
        id_card_front: '', // 身份证含照片的一面（注：当选择身份为个人时为必填参数））
        id_card_back: '', // 身份证带国徽的一面（注：当选择身份为个人时为必填参数）
        soldier_certificate: '', //	否	string	退伍/教练证书
        teachers_certificate: '', // 否	string	培训师资格证书
      };
    },
    /**
     * 初始化文件列表
     */
    initFileListFun(formData) {
      formData = formData || this.formData;
      let { prove } = formData;
      this.all_fileList = { // 文件列表
        'upload1': prove ? [{name: 'upload1', url: prove}] : [],
      };
    },
    handleRemove(file) {
      console.log(file);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      console.log(file);
    },
    /**
     * 输入框监听变化
     */
    inputChangeFun(event,name) {
      console.log(event)
      console.log(name)
      console.log(this.formData)
    },
    /**
     * 将文件提交到服务器
     * $refs：vue 语句，配合 ref="upload" 属性获取节点对象；
     * @parma file {Object}
     */
    mySubmitUploadFileFun(file) {
      const _this = this;
      return new Promise((resolve,reject) => {

        if(file && file.status == 'ready') {

          // file转base64
          fileToBase64(file.raw,function(base64) {
            console.log('bb');
            // base64转file
            let base64_file = dataURLToBlob(base64);
            // 获取oss签名
            _this.myGetOssSignature(file).then((res) => {
              console.log('oss 签名回调 == ',res);
              let { path, key, policy, OSSAccessKeyId, accessid, signature } = res;
              let param = {
                key,
                policy,
                OSSAccessKeyId,
                signature,
              };
              _this.myUploadFileToServers(param,base64_file).then((res) => {
                resolve(path);
              });
            })
          });
        } else {
          throw (new Error('Error: There are no committable objects!'))
          reject();
        }
      })
    },
    /**
     * 文件状态改变时-的钩子函数
     */
    handleChangeFun(file, fileList, name) {
      console.log('文件状态改变时 file == ',file);
      console.log('文件状态改变时 fileList == ',fileList);
      console.log('文件状态改变时 name == ',name);
      // 将文件提交到服务器
      this.mySubmitUploadFileFun(file).then((res) => {
        console.log('远程路径 == ',res)
        this.setUploadSucceedFileFun(res,name); // 设置上传上传成功文件
        // this.recordUploadFileInfo(res,name); // 记录上传文件信息(记录的信息将被提交成功的函数所调用)
        // // 执行节点对象函数
        // this.$refs[name].submit();
      }).catch(() => {});
    },
    /**
     * 上传文件之前-的钩子函数
     */
    handleBeforeUploadFun(file) {
      console.log('上传文件之前 file == ',file);
    },
    /**
     * 上传文件时-的钩子函数
     */
    handleProgressFun(event, file, fileList) {
      console.log('上传文件时 event == ',event);
      console.log('上传文件时 file == ',file);
      console.log('上传文件时 fileList == ',fileList);
    },
    /**
     * 上传文件成功-的钩子函数
     */
    handleSuccessFun(response, file, fileList) {
      console.log('上传文件成功 response == ',response);
      console.log('上传文件成功 file == ',file);
      console.log('上传文件成功 fileList == ',fileList);
      this.setUploadSucceedFileFun(); // 设置上传上传成功文件
    },
    /**
     * 上传文件失败-的钩子函数
     */
    handleErrorFun(err, file, fileList) {
      console.log('上传文件失败 err == ',err);
      console.log('上传文件失败 file == ',file);
      console.log('上传文件失败 fileList == ',fileList);
    },
    /**
     * 移除文件-的钩子函数
     */
    handleRemoveFun(file, fileList,name) {
      console.log('移除文件 file == ',file);
      console.log('移除文件 fileList == ',fileList);
      console.log('移除文件 name == ',name);
      this.removeUploadSucceedFileFun(name); // 删除上传成功的文件
    },
    /**
     * 选择预览文件-的钩子函数
     */
    handlePreviewFun(file) {
      console.log('选择预览文件 file == ',file);
    },
    /**
     * 自定义-上传文件
     */
    myUploadFileFun(e) {
      console.log('上传文件 == ',e)
    },
    /**
     * 获取oss签名
     */
    getOssSignatureFun() {
      return new Promise((resolve,reject) => {
        // 发送请求
        this.myRequest({
          url: '/api/alioss/aliosspolicy'
        }).then(res => {
          let resData = res.data.data;
          resolve(resData);
        })
      })
    },
    /**
     * 记录上传文件信息(记录的信息将被提交成功的函数所调用)
     */
    recordUploadFileInfo(file,name) {
      let resut = {
        file,
        name,
      }
      this.upload_option['record_info'] = { file, name, };
    },
    /**
     * 移除记录上传文件信息
     */
    removeRecordUploadFileInfo() {
      this.upload_option['record_info'] = { file: '', name: '', };
    },
    /**
     * 设置上传上传成功文件
     */
    setUploadSucceedFileFun(file,name) {
      // id_card_front: '', // 身份证含照片的一面（注：当选择身份为个人时为必填参数））
      // id_card_back: '', // 身份证带国徽的一面（注：当选择身份为个人时为必填参数）
      // soldier_certificate: '', //	否	string	退伍/教练证书
      // teachers_certificate: '', // 否	string	培训师资格证书
      return new Promise((resolve,reject) => {
        // let {file,name} = this.upload_option['record_info'];
        if(file) {
          switch(name) {
            case 'upload1': // 身份证人像面
                this.formData['prove'] = file;
              break;
          }
          this.all_fileList[name] = [{name: name, url: file}];
          console.log('设置 this.formData == ',this.formData);
          resolve();
        } else {
          throw (new Error('Error: The file is empty!'))
          reject();
        }
        // 移除记录上传文件信息
        this.removeRecordUploadFileInfo();
      })
    },
    /**
     * 删除上传成功的文件
     */
    removeUploadSucceedFileFun(name) {
      switch(name) {
        case 'upload1': // 身份证人像面
            this.formData['prove'] = '';
          break;
      }
      this.all_fileList[name] = [];
      console.log('删除 this.all_fileList == ',this.all_fileList);
      console.log('删除 this.formData == ',this.formData);
    },
    /**
     * 点击提交按钮
     */
    clickSubmitBtnFun() {

      // 校验数据
      let flag = this.verifyDataFun();
      if(!flag) return;

      // 发起录入请求
      this.sendEnteringRequestFun();
    },
    /**
     * 发起录入请求
     */
    sendEnteringRequestFun() {
      console.log('发起录入请求 查看业务 this.formData == ',this.formData);
      let formData = this.formData;
      this.formData.identity = 2; // 身份  1:个人 2:企业

      this.myRequest({
        method: 'post',
        url: '/api/store/reg',
        data: formData,
      }).then((res) => {
        let resData = res.data.data;
        console.log('录入请求成功 res == ',res);
        // this.mySetStorage({ // 设置本地缓存
        //   name: 'active_test',
        //   value: this.formData,
        //   expires: 60000 * 24 * 31
        // });
        // 发起录入后
        this.sendEnteringAfterFun();

      }).catch((err) => {
        console.log('录入请求失败 err == ',err)
      })
    },
    /**
   * 发起录入后
   */
    sendEnteringAfterFun() {
      // 初始化页面 data 数据
      this.initPageDataFun();
      // 跳转路由
      this.myRouterPush({path: '/succeed'});
    },
    /**
     * 校验数据
     */
    verifyDataFun() {
      let form_data = this.formData;

      console.log('form_data == ',form_data)

      // formData: { //form 表单数据
      //   identity: 1, // 身份  1:个人 2:企业
      //   province: '', // 所在省
      //   city: '', // 所在市
      //   county: '', // 所在区/县
      //   real_name: '', // 姓名
      //   tel: '', // 手机
      //   name: '', // 场地名称/机构名称  身份（2与3）时必填
      //   address: '', // 场地地址/机构地址/地址
      //   prove: '', // 营业执照/证书
      //   lng: '', // 经度
      //   lat: '', // 纬度
      //   id_card_front: '', // 身份证含照片的一面（注：当选择身份为个人时为必填参数））
      //   id_card_back: '', // 身份证带国徽的一面（注：当选择身份为个人时为必填参数）
      //   soldier_certificate: '', //	否	string	退伍/教练证书
      //   teachers_certificate: '', // 否	string	培训师资格证书
      // },

      // 所在城市
      if(!form_data.province || form_data.province == '空' || form_data.province == '省') {
        this.myMessage({
          message: '请选择所在城市！'
        });
        return false;
      } else if(!form_data.city || form_data.city == '空' || form_data.city == '市') {
        this.myMessage({
          message: '请选择所在城市！'
        });
        return false;
      }

      // 法人
      if(!form_data.real_name) {
        this.myMessage({
          message: '请填写法人姓名！'
        });
        return false;
      }

      // 联系方式
      if(!form_data.tel) {
        this.myMessage({
          message: '请填写您的联系方式！'
        });
        return false;
      } else if(!this.REGEXP.is_phone.test(form_data.tel)) {
        this.myMessage({
          message: '请填写添加有效的联系方式'
        });
        return false;
      }

      // 企业名称
      if(!form_data.name) {
        this.myMessage({
          message: '请填写您的企业名称！'
        });
        return false;
      }

      // 营业执照
      if(!form_data.prove) {
        this.myMessage({
          message: '请上传营业执照！'
        });
        return false;
      }

      return true;
    },
  },
  // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
  created() {

  },
  // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
  mounted() {
    // 初始化文件列表
    this.initFileListFun()
  },
  beforeCreate() { // 创建前

  },
  beforeMount() { // 挂载前

  },
  beforeUpdate() { // 更新前

  },
  updated() { // 更新后

  },
  beforeDestroy() { // 销毁前

  },
  destroyed() { // 销毁后

  },
  activated() { // 缓存保持，需要由 keep-alive 触发

  },
}
</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
.enter-container {
  box-sizing: border-box;
  padding-bottom: 50px;
}

.form-module  {
  $rowGutter: 30px;
  width: 70%;
  margin: 0 auto;
  .fm-itme {
    display: flex;
    margin: $rowGutter auto;
    .fm-key {
      box-sizing: border-box;
      min-width: 200px;
      padding-right: 16px;
      .key-title {
        display: flex;
        justify-content: flex-end;
        height: 40px;
        line-height: 40px;
        font-size: 16px;
        color: #444343;
        &.required-style {
          &::before {
            content: '*';
            position: relative;
            top: 4px;
            margin-right: 4px;
            font-size: 26px;
            color: #E62129;
          }
        }
      }
      .exclusive-box {
        box-sizing: border-box;
        padding-right: 10px;
        .eb-text {
          margin-bottom: 6px;
          text-align: right;
          font-size: 12px;
          color: #AAAAAA;
        }
        .eb-list {
          display: flex;
          justify-content: flex-end;
          .el-item {
            width: 30px;
            height: 30px;
            margin-left: 6px;
          }
        }
      }
    }
    .fm-val {
      flex: 1;
      min-width: 0;
      .field-item {
        $height: 40px;
        $font-size: 14px;
        $color:  #444343;
        .tips-text {
          margin-top: 6px;
          font-size: 12px;
          color: $color;
        }
        .input-box {
          display: flex;
          height: $height;
          // border: 1px solid #787878;
          border: 1px solid #DCDFE6;
          border-radius: 4px;
          .input-icon {
            width: $height;
            height: $height;
            line-height: $height;
            text-align: center;
            font-size: $font-size;
            color: $color;
            .iconfont {
              font-size: 24px;
            }
          }
          &::v-deep .el-input {
            height: $height;
            .el-input__inner {
              border: none;
              height: $height;
              line-height: $height;
              padding: 0 10px;
            }
          }
        }
        .select-city {
          &.active-one {
            &::v-deep {
              .distpicker-address-wrapper {
                label:nth-child(1) select {
                  color: $color;
                }
              }
            }
          }
          &.active-two {
            &::v-deep {
              .distpicker-address-wrapper {
                label:nth-child(2) select {
                  color: $color;
                }
              }
            }
          }
          &.active-three {
            &::v-deep {
              .distpicker-address-wrapper {
                label:nth-child(3) select {
                  color: $color;
                }
              }
            }
          }
          &::v-deep {
            .distpicker-address-wrapper select {
              padding: 0;
              height: $height;
              // border: 1px solid #787878;
              border: 1px solid #DCDFE6;
              border-radius: 4px;
              outline: none;
              font-size: $font-size;
              // color: $color;
              color: #c0c4d6;
              margin-right: 16px;
              box-sizing: border-box;
              padding: 0 10px;
              &[disabled] {
                background-color: #F5F7FA;
              }
              option {
                color: $color;
              }
            }
          }
        }
      }
    }
  }
  .fm-rest {
    .fm-confirm {
      width: 200px;
      margin: 60px auto 20px;
      .fake-btn {
        width: 100%;
        font-size: 14px;
        color: #fff;
        border: 1px solid $common-number1-color;
        background: $common-number1-color;
        outline: none;
        &.el-button:hover,&.el-button:focus,&.el-button:active {
          color: none;
          border-color: none;
          background-color: none;
        }
      }
    }
    .fm-tips {
      text-align: center;
      font-size: 14px;
      color: #787878;
    }
  }
  .upload-module {
    display: flex;
    .upload-main {
      margin-right: 16px;
      &.hide-upload {
        &::v-deep .el-upload {
          display: none;
        }
      }
      &.identity-card {
        &::v-deep {
          .el-upload--picture-card {
            width: 192px;
            height: 162px;
            border: none;
            outline: none;
          }
          .el-upload-list--picture-card .el-upload-list__item {
            width: 192px;
            height: 162px;
          }
        }
        .befoer-box {
          width: 192px;
          height: 162px;
          line-height: 1.5;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .bb-cover {
            width: 150px;
            height: 100px;
            margin: 0 auto;
          }
          .bb-text {
            margin-top: 4px;
            font-size: 12px;
            color: #444343;
            .diff-style {
              color: #E62129;
            }
          }
        }
      }
      .default-befoer,.befoer-box{
        border-radius: 4px;
        overflow: hidden;
        border: 1px dashed transparent;
        background: #fff;
        &:hover {
          border-color: rgb(50, 184, 247);
        }
      }
      &::v-deep {
        .el-upload-list--picture-card .el-upload-list__item-thumbnail {
          object-fit: scale-down;
        }
      }
      // &::v-deep .el-upload--picture-card {
      //   border: none;
      //   outline: none;
      // }
      // &::v-deep .el-upload-list--picture-card .el-upload-list__item,
      // &::v-deep .el-upload--picture-card,
      .default-befoer {
        width: 80px;
        height: 80px;
        line-height: 80px;
        text-align: center;
        .db-icon {
          display: inline-block;
          width: 18px;
          height: 18px;
          line-height: 18px;
          color: #AAAAAA;
          .iconfont {
            font-size: 18px;
          }
        }
      }
    }
  }
}
.form-title-box {
  box-sizing: border-box;
  // padding: 50px 0 ;
  background-color: #fff;
  border-bottom: 5px solid #f4f4f4;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &::v-deep {
    .ft-descriptions {
      padding-left: 20px;
      // .diff {
      //   padding-right: 10px;
      // }
    }
  }
}
</style>
